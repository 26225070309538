.welcome-container {
  padding: 1rem 2rem;
}

.logo {
  width: 138px;
  height: 109px;
}
.logo > img {
  width: 100px;
  height: 70px;
  padding-left: -2rem;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: -90px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header > :nth-child(1) {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 50px;
  text-align: center;
  color: #eee3e8;
  font-family: "sahitya", Arial, Helvetica, sans-serif;
}

.header > :nth-child(2) {
  font-family: "sahitya", Arial, Helvetica, sans-serif;
  color: #624a4a;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;

  text-align: center;
}
.header > :nth-child(3) {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  text-align: center;
  color: rgba(110, 6, 93, 0.6);
  font-family: "sahitya", Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 425px) {
  .welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0;
  }
  .logo {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 3rem 1rem;
  }
  .logo > img {
    width: 50%;
    height: 50%;
  }

  .header {
    display: flex;
    flex-direction: column;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    gap: 1rem;
  }

  .header > :nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 200%;
    line-height: 30px;
    text-align: center;
    color: #eee3e8;
    font-family: "sahitya", Arial, Helvetica, sans-serif;
  }
  .header > :nth-child(2) {
    font-family: "sahitya", Arial, Helvetica, sans-serif;
    color: #624a4a;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
  .header > :nth-child(3) {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    text-align: center;
    color: rgba(110, 6, 93, 0.6);
    font-family: "sahitya", Arial, Helvetica, sans-serif;
  }
}
